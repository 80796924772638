import { Box, Container } from '@mui/material';

import './landing.css';
import Experiences from './Content/Experiences/experiences';
import SliderProducts from './Content/Products/slider';
import SliderRecipes from './Content/Recipes/slider';
import AnimatedBowl from '../components/AnimatedBowl/animated-bowl';
import HomeImage from '../../src/assets/images/070224_Toptops_portadaWeb_16_9_v1.png'
import ImageDivider from '../components/Divider/divider';

function Landing({ refs }) {
  return (
    <Container maxWidth="xl" className="landing-container" disableGutters>
      <Box className="header-container">
        <img
          src={HomeImage}
          style={{ width: "100%", height: "auto" }}
        />
        <ImageDivider />
      </Box>
      <div ref={refs.experiences}>
        <Experiences />
      </div>
      <div ref={refs.animation}>
        <AnimatedBowl />
      </div>
      <div ref={refs.products}>
        <SliderProducts />
      </div>
      <div ref={refs.recipes}>
        <SliderRecipes />
      </div>
    </Container>
  );
}

export default Landing;